import canUseDom from '@clearscore-group/lib.helpers.can-use-dom';

const useUtmSource = () => {
    if (!canUseDom()) return undefined;
    const { searchParams } = new URL(window.location.href);
    const utmSource = searchParams.get('utm_source');
    return utmSource;
};

export default useUtmSource;
