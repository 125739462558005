import { EN_GB, EN_ZA, EN_AU, EN_CA, EN_NZ } from '@clearscore-group/lib.config.i18n';

export default {
    [EN_GB]: {
        section: 'your-identity',
        header: 'Your identity could be at risk. Keep it protected',
        details: [
            {
                header: 'Data breaches are on the rise',
                copy: 'If a company gets hacked, your personal information could be leaked on the dark web without you knowing.',
                icon: 'password',
                key: 'detail-1',
            },
            {
                header: 'Identity fraud costs time and money',
                copy: 'Victims of fraud can spend months restoring their identity, and may not always recover the money stolen.',
                icon: 'savings',
                key: 'detail-2',
            },
            {
                header: 'Stolen data is sold on the dark web',
                copy: 'Fraudsters trade and sell personal information on dark web markets, and use the details to commit identity fraud.',
                icon: 'fingerprint',
                key: 'detail-3',
            },
            {
                header: 'It could happen to you',
                copy: 'With over 500 identities being stolen every day in the UK, it pays to take action and keep your personal information safe.',
                icon: 'darkWeb',
                key: 'detail-4',
            },
        ],
        ctaText: 'Get ClearScore Protect',
        ctaTextLong: 'Get ClearScore Protect',
        ctaUrl: 'https://app.clearscore.com/signup',
    },
    [EN_ZA]: {
        section: 'your-identity',
        header: 'Your identity could be at risk. Keep it protected',
        details: [
            {
                header: 'Data breaches are on the rise',
                copy: 'If a company gets hacked, your personal information may be leaked without your knowledge.',
                icon: 'password',
                key: 'detail-1',
            },
            {
                header: 'Identity fraud costs time and money',
                copy: 'Victims can spend months restoring their identity, and they may never recover their stolen money.',
                icon: 'savings',
                key: 'detail-2',
            },
            {
                header: 'Stolen data is sold on the dark web',
                copy: 'Criminals trade personal information on the dark web, where it’s used to commit identity fraud.',
                icon: 'fingerprint',
                key: 'detail-3',
            },
            {
                header: 'It could happen to you',
                copy: 'With impersonation fraud up by 337% since the Covid pandemic in South Africa, it pays to take action and keep your personal information safe.',
                icon: 'darkWeb',
                key: 'detail-4',
            },
        ],
        ctaText: 'Get ClearScore Protect',
        ctaTextLong: 'Get ClearScore Protect',
        ctaUrl: 'https://app.clearscore.com/signup?lng=en-za',
    },
    [EN_AU]: {
        section: 'your-identity',
        header: 'Your identity could be at risk. Keep it protected',
        details: [
            {
                header: 'Data breaches are on the rise',
                copy: 'If a company gets hacked, your personal information could be leaked on the dark web without you knowing.',
                icon: 'password',
                key: 'detail-1',
            },
            {
                header: 'Identity fraud costs time and money',
                copy: 'Victims of fraud can spend months restoring their identity, and may not always recover the money stolen.',
                icon: 'savings',
                key: 'detail-2',
            },
            {
                header: 'Stolen data is sold on the dark web',
                copy: 'Fraudsters trade and sell personal information on dark web markets, and use the details to commit identity fraud.',
                icon: 'fingerprint',
                key: 'detail-3',
            },
            {
                header: 'It could happen to you',
                copy: 'According to the Australian Institute of Criminology, 1 in 4 Australians will experience identity theft in their lifetime. You can learn more <0>here</0>',
                infoUrl: 'https://www.clearscore.com/au/learn/protect',
                icon: 'darkWeb',
                key: 'detail-4',
            },
        ],
        ctaText: 'Get ClearScore Protect',
        ctaTextLong: 'Get ClearScore Protect',
        ctaUrl: 'https://app.clearscore.com/signup?lng=en-au',
    },
    [EN_NZ]: {
        section: 'your-identity',
        header: 'Your identity could be at risk. Keep it protected',
        details: [
            {
                header: 'Data breaches are on the rise',
                copy: 'If a company gets hacked, your personal information could be leaked on the dark web without you knowing.',
                icon: 'password',
                key: 'detail-1',
            },
            {
                header: 'Identity fraud costs time and money',
                copy: 'Victims of fraud can spend months restoring their identity, and may not always recover the money stolen.',
                icon: 'savings',
                key: 'detail-2',
            },
            {
                header: 'Stolen data is sold on the dark web',
                copy: 'Fraudsters trade and sell personal information on dark web markets, and use the details to commit identity fraud.',
                icon: 'fingerprint',
                key: 'detail-3',
            },
            {
                header: 'It could happen to you',
                copy: 'The 2022 Norton Cyber Safety Insights Report found that one in five New Zealand adults surveyed have experienced identity theft.',
                icon: 'darkWeb',
                key: 'detail-4',
            },
        ],
        ctaText: 'Get ClearScore Protect',
        ctaTextLong: 'Get ClearScore Protect',
        ctaUrl: 'https://app.clearscore.com/signup?lng=en-nz',
    },
    [EN_CA]: {
        section: 'your-identity',
        header: 'Your identity could be at risk. Keep it protected',
        details: [
            {
                header: 'Data breaches are on the rise',
                copy: 'If a company gets hacked, your personal information could be leaked on the dark web without your knowledge.',
                icon: 'password',
                key: 'detail-1',
            },
            {
                header: 'Identity fraud costs time and money',
                copy: 'Victims of fraud can spend months restoring their identity, and may not always recover the money stolen.',
                icon: 'savings',
                key: 'detail-2',
            },
            {
                header: 'Stolen data is sold on the dark web',
                copy: 'Fraudsters trade and sell personal information on dark web markets, and use the details to commit identity fraud.',
                icon: 'fingerprint',
                key: 'detail-3',
            },
            {
                header: 'It could happen to you',
                copy: 'According to the Canadian Anti-Fraud Centre, 1 in 5 people will fall victim to identity fraud, and the rate of identity theft has almost doubled since the pandemic. You can learn more <0>here</0>.',
                infoUrl: 'https://www.clearscore.com/ca/learn/protect',
                icon: 'darkWeb',
                key: 'detail-4',
            },
        ],
        ctaText: 'Get ClearScore Protect',
        ctaTextLong: 'Get ClearScore Protect',
        ctaUrl: 'https://app.clearscore.com/signup?lng=en-ca',
    },
};
