import type { ReactElement, ReactNode } from 'react';
import React from 'react';
import cx from 'classnames';
import toTitleCase from '@clearscore-group/lib.normalize.title-case';

import styles from './container.module.css';

const Theme = {
    TRANSPARENT: 'TRANSPARENT',
    WHITE: 'WHITE',
    LIGHT: 'LIGHT',
    DARK: 'DARK',
} as const;

const Size = {
    TINY: 'TINY',
    SMALL: 'SMALL',
    MEDIUM: 'MEDIUM',
    MEDIUM_LARGE: 'MEDIUM_LARGE',
    LARGE: 'LARGE',
    HUGE: 'HUGE',
    MAX: 'MAX',
} as const;

const Spacing = {
    NONE: 'NONE',
    MICRO: 'MICRO',
    TINY: 'TINY',
    SMALL: 'SMALL',
    MEDIUM: 'MEDIUM',
    LARGE: 'LARGE',
    BIG: 'BIG',
    HUGE: 'HUGE',
    SUPER: 'SUPER',
    JUMBO: 'JUMBO',
} as const;

interface IContainerProps {
    size?: (typeof Size)[keyof typeof Size];
    theme?: (typeof Theme)[keyof typeof Theme];
    top?: (typeof Spacing)[keyof typeof Spacing];
    bottom?: (typeof Spacing)[keyof typeof Spacing];
    dataId?: string;
    children: ReactNode;
    isFullHeight?: boolean;
    excludeGutter?: boolean;
    isTranslucent?: boolean;
    id?: string;
}

export interface ContainerExport {
    (props: IContainerProps): ReactElement;
    Spacing: typeof Spacing;
    Theme: typeof Theme;
    Size: typeof Size;
}

const Container: ContainerExport = ({
    size = Size.MAX,
    theme = Theme.TRANSPARENT,
    top = Spacing.NONE,
    bottom = Spacing.NONE,
    isFullHeight = false,
    excludeGutter = false,
    dataId,
    children,
    isTranslucent = false,
}) => {
    const containerClass = cx(styles.container, styles[`isTheme${toTitleCase(theme)}`], {
        [styles.isFullHeight]: isFullHeight,
        [styles.isTranslucent]: isTranslucent,
    });
    const contentContainerClass = cx(
        styles.contentContainer,
        styles[`isSize${toTitleCase(size)}`],
        styles[`isTopSpacing${toTitleCase(top)}`],
        styles[`isBottomSpacing${toTitleCase(bottom)}`],
        {
            [styles.excludeGutter]: excludeGutter,
        },
    );
    return (
        <div className={containerClass} data-qa="container" data-id={dataId}>
            <div className={contentContainerClass} data-qa="content-container">
                {children}
            </div>
        </div>
    );
};

Container.Spacing = Spacing;
Container.Theme = Theme;
Container.Size = Size;

export default Container;
