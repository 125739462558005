import React from 'react';
import type { PageProps } from 'gatsby';
import { graphql } from 'gatsby';
import type { ImageDataLike } from 'gatsby-plugin-image';
import { getImage } from 'gatsby-plugin-image';
import Protect from '@clearscore/website.protect-landing';

import ProtectVideo from '../assets/gb/protect/protect_cut.mp4';
import avatar from '../assets/gb/protect/avatar_protect_250x250.jpg';
import Layout from '../components/layout';

const ProtectTemplate = ({
    data,
    location,
    pageContext: { market },
}: PageProps<Queries.ProtectTemplateQuery, { market: string }>): React.ReactElement => {
    const shield = getImage(data.emptyShield as ImageDataLike);
    const sign = getImage(data.scoreShield as ImageDataLike);
    const phone = getImage(data.phoneImage as ImageDataLike);
    const faq = getImage(data.faqImage as ImageDataLike);

    return (
        <Layout
            meta={{
                title: `Protect Your Identity Online | ClearScore ${market.toUpperCase()}`,
                description:
                    'We find your stolen passwords, help you fix security problems and show you how to defend yourself against fraud.',
                image: avatar,
            }}
            location={location.pathname}
        >
            {({ commonModules }): React.ReactElement => (
                <Protect
                    appStoreConfig={commonModules.app_store}
                    cookiePolicyConfig={commonModules.cookie_policy}
                    video={ProtectVideo}
                    sign={sign}
                    phone={phone}
                    faq={faq}
                    shield={shield}
                />
            )}
        </Layout>
    );
};

export const query = graphql`
    query ProtectTemplate {
        emptyShield: file(absolutePath: { regex: "/protect/empty_shield.png/" }) {
            childImageSharp {
                gatsbyImageData(quality: 80, breakpoints: [375, 768, 1024, 1200, 1440], layout: FULL_WIDTH)
            }
        }
        scoreShield: file(absolutePath: { regex: "/protect/dark_web_sign.png/" }) {
            childImageSharp {
                gatsbyImageData(quality: 80, breakpoints: [375, 768, 1024, 1200, 1440], layout: FULL_WIDTH)
            }
        }
        phoneImage: file(absolutePath: { regex: "/protect/phone.png/" }) {
            childImageSharp {
                gatsbyImageData(quality: 80, breakpoints: [375, 768, 1024, 1200, 1440], layout: FULL_WIDTH)
            }
        }
        faqImage: file(absolutePath: { regex: "/protect/FAQ_bg.jpg/" }) {
            childImageSharp {
                gatsbyImageData(quality: 90, breakpoints: [375, 768, 1024, 1200, 1440], layout: FULL_WIDTH)
            }
        }
    }
`;

export default ProtectTemplate;
