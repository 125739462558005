/* eslint-disable prefer-rest-params */
function join(rules) {
    return function (value, data) {
        return rules
            .map((rule) => {
                const ruleValue = value && value.trim ? value.trim() : value;
                return rule(ruleValue, data);
            })
            .filter((error) => !!error)[0];
    };
}

function index(rules) {
    return function () {
        const data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
        const errors = {};
        Object.keys(rules).forEach((key) => {
            const rule = join([].concat(rules[key])); // concat enables both functions and arrays of functions

            const error = rule(data[key], data);

            if (error) {
                errors[key] = error;
            }
        });
        return errors;
    };
}

export default index;
