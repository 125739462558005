import React from 'react';
import PropTypes from 'prop-types';
import Link from 'gatsby-link';
import { GatsbyImage } from 'gatsby-plugin-image';
import { mediaQuery } from '@clearscore/ui.rainbow.tokens';
import Text from '@clearscore/ui.rainbow.text';
import useLanguage from '@clearscore-group/lib.hooks.use-language';
import Container from '@clearscore/ui.rainbow.container';
import Button from '@clearscore/ui.rainbow.button';
import Spacer from '@clearscore/ui.rainbow.spacer';
import Stack from '@clearscore/ui.rainbow.stack';

import lang from './lib/lang';
import styles from './plus.module.css';
import { PROTECT_PLUS_URL } from './lib/constants';

const plusPreset = {
    tag: Text.tags.SPAN,
    weight: Text.weights.LIGHT,
    type: [Text.types.BIG, Text.types.HUGE],
    breakpoints: [null, mediaQuery['rnb-mq-medium']],
};

const headingPreset = {
    type: [Text.types.MEDIUM, Text.types.LARGE],
    breakpoints: [null, mediaQuery['rnb-mq-medium']],
};

const Plus = ({ shield }) => {
    const language = useLanguage(lang);

    return (
        <Container
            size={Container.Size.SMALL}
            theme={Container.Theme.LIGHT}
            top={Container.Spacing.JUMBO}
            bottom={Container.Spacing.JUMBO}
        >
            <div className={styles.plus} data-qa="plus">
                <div className={styles.shield}>
                    {shield && <GatsbyImage image={shield} alt="Protect Plus" />}

                    <div className={styles.shieldText}>
                        <Text.H1 tag={Text.tags.H2} dataId="plus-title">
                            {language.protect}
                            <Text {...plusPreset} tag={Text.tags.SPAN}>
                                {language.plus}
                            </Text>
                        </Text.H1>
                    </div>
                </div>

                <Spacer all={{ vertical: Spacer.spacings.LARGE }}>
                    <Text {...headingPreset} dataId="plus-heading">
                        {language.heading}
                    </Text>
                </Spacer>

                <Spacer all={{ bottom: Spacer.spacings.HUGE }}>
                    <Stack all={Stack.spacings.LARGE}>
                        <Text.Body1 dataId="plus-benefits-scanning">{language.benefits.scanning}</Text.Body1>
                        <Text.Body1 dataId="plus-benefits-support">{language.benefits.support}</Text.Body1>
                    </Stack>
                </Spacer>

                <Link to={PROTECT_PLUS_URL} data-qa="plus-cta">
                    <Button size={Button.sizes.LARGE}>{language.cta}</Button>
                </Link>
            </div>
        </Container>
    );
};

Plus.propTypes = {
    shield: PropTypes.node,
};

Plus.defaultProps = {
    shield: null,
};

export default Plus;
