import { EN_GB } from '@clearscore-group/lib.config.i18n';

export default {
    [EN_GB]: {
        protect: 'Protect',
        plus: 'Plus',
        heading: 'Round-the-clock identity protection and fraud defence',
        benefits: {
            scanning:
                'ClearScore Protect Plus is round-the-clock identity protection. Using advanced web scanning, we find breaches of your personal data on the dark web and beyond.',
            support:
                "You'll receive breach alerts, daily updates when there’s a change to your credit report and support from a dedicated fraud case manager over the phone.",
        },
        cta: 'Learn more about Protect Plus',
    },
};
