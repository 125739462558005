import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import TextInput from '@clearscore/ui.rainbow.text-input';
import Button from '@clearscore/ui.rainbow.button';
import { actions, selectors } from '@clearscore-group/lib.redux.prospect';
import { setPublisherCookie } from '@clearscore-group/lib.helpers.cookies';

import validator from './lib/validator';
import useWebsiteTracking from '../../hooks/tracking';
import styles from './create-prospect-form.module.css';
import useUtmSource from '../../hooks/use-utm-source';

const FORM_NAME = 'prospectForm';
const EMAIL_NAME = 'email';

export const inputThemes = TextInput.Theme;
export const buttonThemes = Button.themes;

const CreateProspectForm = ({ placeholder, language, inputTheme, buttonTheme, dispatch }) => {
    const { trackEmailSignupAttempt } = useWebsiteTracking();
    const { isPending } = useSelector(selectors.getPredicate).fetch;
    const [email, setEmail] = useState('');
    const getFormValues = () => ({
        email,
    });
    const emailHasErrors = () => !!validator(getFormValues()).email;

    const utmSource = useUtmSource();

    const trackEmailValidation = () => {
        trackEmailSignupAttempt({ language, isEmailSubmitSuccessful: !emailHasErrors() });
    };
    const updateEmail = (e) => {
        setEmail(e.target.value);
    };

    const onSubmit = (e) => {
        e.preventDefault();
        if (!emailHasErrors()) {
            setPublisherCookie(`?utm_source=${utmSource || 'protect_landing'}&utm_campaign=protect&utm_medium=cta`);
            dispatch(actions.register(getFormValues()));
        }
    };

    return (
        <form name={FORM_NAME} className={styles.emailForm} onSubmit={onSubmit} data-qa="prospect-form">
            <div className={styles.input}>
                <TextInput
                    value={email}
                    name={EMAIL_NAME}
                    type={TextInput.Type.EMAIL}
                    theme={TextInput.Theme[inputTheme]}
                    placeholder={placeholder}
                    onChange={updateEmail}
                />
            </div>

            <Button
                dataId="prospect-form-submit"
                htmlType="submit"
                size={Button.sizes.LARGE}
                theme={Button.themes[buttonTheme]}
                isLoading={isPending}
                isNarrow
                onClick={() => trackEmailValidation()}
            >
                {language.ctaText}
            </Button>
        </form>
    );
};

CreateProspectForm.propTypes = {
    language: PropTypes.shape({
        section: PropTypes.string,
        heading: PropTypes.string,
        blurb: PropTypes.string,
        ctaText: PropTypes.string,
        ctaTextLong: PropTypes.string,
        ctaUrl: PropTypes.string,
    }).isRequired,
    placeholder: PropTypes.string,
    dispatch: PropTypes.func.isRequired,
    inputTheme: PropTypes.string.isRequired,
    buttonTheme: PropTypes.string.isRequired,
};

CreateProspectForm.defaultProps = {
    placeholder: null,
};

const Form = ({ showEmail, language, inputTheme, buttonTheme }) => {
    const { trackProtectClick } = useWebsiteTracking();
    const dispatch = useDispatch();
    const emailPlaceholder = 'Enter your email';
    const utmSource = useUtmSource();
    return showEmail ? (
        <CreateProspectForm
            placeholder={emailPlaceholder}
            language={language}
            inputTheme={inputTheme}
            buttonTheme={buttonTheme}
            dispatch={dispatch}
        />
    ) : (
        <Button
            dataId="protect-cta"
            onClick={(e) => {
                setPublisherCookie(`?utm_source=${utmSource || 'protect_landing'}&utm_campaign=protect&utm_medium=cta`);
                trackProtectClick({ e, language });
            }}
            href={language.ctaUrl}
            size={Button.sizes.LARGE}
            theme={Button.themes[buttonTheme]}
            isNarrow
        >
            {language.ctaTextLong}
        </Button>
    );
};

Form.propTypes = {
    showEmail: PropTypes.bool.isRequired,
    language: PropTypes.shape({
        section: PropTypes.string,
        heading: PropTypes.string,
        blurb: PropTypes.string,
        ctaText: PropTypes.string,
        ctaTextLong: PropTypes.string,
        ctaUrl: PropTypes.string,
    }).isRequired,
    inputTheme: PropTypes.string,
    buttonTheme: PropTypes.string.isRequired,
};

Form.defaultProps = {
    inputTheme: inputThemes.DARK,
};

export default Form;
