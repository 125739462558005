export const spacing = {
    NONE: 'none',
    MICRO: 'micro',
    TINY: 'tiny',
    SMALL: 'small',
    MEDIUM: 'medium',
    LARGE: 'large',
    BIG: 'big',
    HUGE: 'huge',
    SUPER: 'super',
    JUMBO: 'jumbo',
} as const;

export const breakpointNames = ['all', 'small', 'phablet', 'medium', 'large', 'huge'] as const;
