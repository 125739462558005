/* eslint-disable prefer-rest-params */
import isEmpty from '@clearscore-group/lib.validation.is-empty';

/*
 * isEmail Validation
 * isEmail checks to see if a value is in an email format
 * isEmailValidation returns the DEFAULT_MESSAGE if value is not an email
 */
export const DEFAULT_MESSAGE = 'Valid email format required';
const emailRegEx = /^[A-Z0-9._%\-+']+@[A-Z0-9.-]+\.[A-Z]{2,24}$/i;

function isEmail(value) {
    return !isEmpty(value) && emailRegEx.test(value);
}

export function isEmailValidation() {
    const errorMessage = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : DEFAULT_MESSAGE;
    return function error(value) {
        return !isEmail(value) ? errorMessage : undefined;
    };
}

export default isEmail;
