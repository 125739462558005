import { EN_GB, EN_ZA, EN_AU, EN_CA, EN_NZ } from '@clearscore-group/lib.config.i18n';

export default {
    [EN_GB]: {
        section: 'faq',
        header: 'Frequently asked questions',
        faqs: [
            {
                header: 'Is it safe?',
                copy: 'Yes. We scan the dark web securely and privately without revealing any of your data',
                key: 'faq-1',
            },
            {
                header: 'What does it mean if my password is on the dark web?',
                copy: 'Criminals may use it to try to hack into your accounts. They could then use your details to apply for financial products in your name or commit other types of fraud.',
                key: 'faq-2',
            },
            {
                header: 'What should I do if my password has been stolen?',
                copy: 'Take action and change your password. ClearScore Protect will guide you through this and give you helpful security tips to keep your identity safe.',
                key: 'faq-3',
            },
            {
                header: 'How do I get ClearScore Protect?',
                copy: "To access ClearScore Protect, you'll need a ClearScore account. You can sign up for free in 2 minutes.",
                key: 'faq-4',
            },
        ],
    },
    [EN_ZA]: {
        section: 'faq',
        header: 'Frequently asked questions',
        faqs: [
            {
                header: 'Is it safe?',
                copy: ' Yes. We scan the dark web securely and  privately, without revealing any of your data.',
                key: 'faq-1',
            },
            {
                header: 'What is the dark web?',
                copy: 'The dark web is a series of private networks that can only be accessed via special software. Criminals use the dark web as a marketplace for selling anything and everything illegal. This includes your personal information, such as your passwords.',
                key: 'faq-2',
            },
            {
                header: 'What does it mean if my password is on the dark web?',
                copy: 'Criminals may use it to hack into your accounts. They can then apply for financial products in your name or commit other types of fraud.',
                key: 'faq-3',
            },
            {
                header: 'What should I do if my password has been stolen?',
                copy: 'Take action and change your password. ClearScore Protect will guide you through this and give you helpful security tips to keep your identity safe.',
                key: 'faq-4',
            },
            {
                header: 'How do I get ClearScore Protect?',
                copy: "To access ClearScore Protect, you'll need a ClearScore account. You can sign up for free in just 2 minutes.",
                key: 'faq-5',
                ctaUrl: 'https://www.clearscore.com/za/learn/protect/what-is-the-dark-web-heres-what-you-need-to-know',
            },
        ],
    },
    [EN_AU]: {
        section: 'faq',
        header: 'Frequently asked questions',
        faqs: [
            {
                header: 'Is it safe?',
                copy: 'Yes. We scan the dark web securely and privately without revealing any of your data.',
                key: 'faq-1',
            },
            {
                header: 'What does it mean if my password is on the dark web?',
                copy: 'Criminals may use it to try to hack into your accounts. They could then use your details to apply for financial products in your name or commit other types of fraud.',
                key: 'faq-2',
            },
            {
                header: 'What should I do if my password has been stolen?',
                copy: 'Take action and change your password. ClearScore Protect will guide you through this and give you helpful security tips to keep your identity safe.',
                key: 'faq-3',
            },
            {
                header: 'How do I get ClearScore Protect?',
                copy: "To access ClearScore Protect, you'll need a ClearScore account. You can sign up for free in 2 minutes.",
                key: 'faq-4',
            },
        ],
    },
    [EN_NZ]: {
        section: 'faq',
        header: 'Frequently asked questions',
        faqs: [
            {
                header: 'Is it safe?',
                copy: 'Yes. We scan the dark web securely and privately without revealing any of your data.',
                key: 'faq-1',
            },
            {
                header: 'What does it mean if my password is on the dark web?',
                copy: 'Criminals may use it to try to hack into your accounts. They could then use your details to apply for financial products in your name or commit other types of fraud.',
                key: 'faq-2',
            },
            {
                header: 'What should I do if my password has been stolen?',
                copy: 'Take action and change your password. ClearScore Protect will guide you through this and give you helpful security tips to keep your identity safe.',
                key: 'faq-3',
            },
            {
                header: 'How do I get ClearScore Protect?',
                copy: "To access ClearScore Protect, you'll need a ClearScore account. You can sign up for free in 2 minutes.",
                key: 'faq-4',
            },
        ],
    },
    [EN_CA]: {
        section: 'faq',
        header: 'Frequently asked questions',
        faqs: [
            {
                header: 'Is it safe?',
                copy: ' Yes. We scan the dark web securely and  privately, without revealing any of your data.',
                key: 'faq-1',
            },
            {
                header: 'What is the dark web?',
                copy: 'The dark web is a series of private networks that can only be accessed via special software. Criminals use the dark web as a marketplace for selling anything and everything illegal. This includes your personal information, such as your passwords.',
                key: 'faq-2',
            },
            {
                header: 'What does it mean if my password is on the dark web?',
                copy: 'Criminals may use it to hack into your accounts. They can then apply for financial products in your name or commit other types of fraud.',
                key: 'faq-3',
            },
            {
                header: 'What should I do if my password has been stolen?',
                copy: 'Take action and change your password. ClearScore Protect will guide you through this and give you helpful security tips to keep your identity safe.',
                key: 'faq-4',
            },
            {
                header: 'How do I get ClearScore Protect?',
                copy: "To access ClearScore Protect, you'll need a ClearScore account. You can sign up for free in just 3 minutes.",
                key: 'faq-5',
                ctaUrl: 'https://www.clearscore.com/ca/learn/protect/what-is-the-dark-web-heres-what-you-need-to-know',
            },
        ],
    },
};
