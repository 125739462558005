import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import IconPassword from '@clearscore/rainbow.icons.password';
import IconSavings from '@clearscore/rainbow.icons.savings';
import IconFingerprint from '@clearscore/rainbow.icons.fingerprint';
import IconDarkWeb from '@clearscore/rainbow.icons.dark-web';
import Text from '@clearscore/ui.rainbow.text';
import Container from '@clearscore/ui.rainbow.container';
import { GatsbyImage } from 'gatsby-plugin-image';
import useLanguage from '@clearscore-group/lib.hooks.use-language';
import interpolateWithComponents from '@clearscore-group/lib.helpers.interpolate-with-components';

import Form, { buttonThemes } from '../create-prospect-form';
import styles from './details.module.css';
import lang from './lib/lang';

const getIcon = (icon) => {
    switch (icon) {
        case 'password':
            return <IconPassword className={styles.icon} />;
        case 'savings':
            return <IconSavings className={styles.icon} />;
        case 'fingerprint':
            return <IconFingerprint className={styles.icon} />;
        default:
            return <IconDarkWeb className={styles.icon} />;
    }
};

const Details = ({ details }) =>
    details.map(({ header, copy, icon, key, infoUrl }) => (
        <li key={key} className={styles.textContainer} data-qa="detail-container">
            <div className={styles.iconContainer}>
                {getIcon(icon)}
                <div className={styles.circle} />
            </div>
            <div className={styles.textHeader}>
                <Text.H3>{header}</Text.H3>
            </div>
            <div className={styles.textBody}>
                {infoUrl ? (
                    <Text.Body1>
                        {interpolateWithComponents(copy, [
                            {
                                Component: Text.Link,
                                props: { href: infoUrl, isNewTab: true },
                            },
                        ])}
                    </Text.Body1>
                ) : (
                    <Text.Body1>{copy}</Text.Body1>
                )}
            </div>
        </li>
    ));

const YourDetails = ({ sign }) => {
    const language = useLanguage(lang);
    return (
        <section className={cx(styles.section, styles.yourDetails)} data-qa="details">
            <Container size={Container.Size.HUGE}>
                <div className={cx(styles.sectionContent)}>
                    <div className={styles.title}>
                        <Text.H1 tag={Text.tags.H2} dataId="details-title">
                            {language.header}
                        </Text.H1>
                    </div>
                    <div className={styles.yourDetailsContent}>
                        <ul className={styles.detailList}>
                            {sign && (
                                <li className={styles.signContainer}>
                                    <GatsbyImage image={sign} alt="Dark Web Market Sign" />
                                </li>
                            )}
                            <Details details={language.details} />
                        </ul>
                    </div>
                    <div className={styles.getProtect}>
                        <Form showEmail={false} language={language} buttonTheme={buttonThemes.LIGHT} />
                    </div>
                </div>
            </Container>
        </section>
    );
};

YourDetails.propTypes = {
    sign: PropTypes.node,
};

YourDetails.defaultProps = {
    sign: null,
};

export default YourDetails;
