import type {
    ChangeEvent,
    HTMLAttributes,
    LegacyRef,
    MouseEvent,
    MutableRefObject,
    ReactElement,
    ReactNode,
    RefObject,
} from 'react';
import React, { isValidElement } from 'react';
import cx from 'classnames';
import Text from '@clearscore/ui.rainbow.text';
import Criteria from '@clearscore/ui.rainbow.criteria';
import toTitleCase from '@clearscore-group/lib.normalize.title-case';
import ClickableIcon from '@clearscore/ui.rainbow.clickable-icon';
import StatusErrorIcon from '@clearscore/rainbow.icons.status-error';

import styles from './text-input.module.css';
import { Theme, Type } from './constants';

export interface ITextInputProps extends HTMLAttributes<HTMLInputElement> {
    name: string;
    value: string | number;
    placeholder?: string;
    id?: string;
    dataId?: string;
    suffix?: ReactNode;
    prepend?: string | null;
    isDirty?: boolean;
    isValid?: boolean;
    isLightBg?: boolean;
    isInvalid?: boolean;
    isDisabled?: boolean;
    autoComplete?: string;
    errorMessage?: string;
    isDockedTop?: boolean;
    elementRef?:
        | RefObject<HTMLInputElement>
        | LegacyRef<HTMLInputElement>
        | MutableRefObject<HTMLInputElement>
        | undefined;
    type?: (typeof Type)[keyof typeof Type];
    theme?: (typeof Theme)[keyof typeof Theme];
    onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
    pattern?: string;
    onClear?: (event: MouseEvent) => void;
    clearIcon?: boolean;
    autoFocus?: boolean;
}

export interface TextInputExport {
    (props: ITextInputProps): ReactElement;
    Theme: typeof Theme;
    Type: typeof Type;
}

const TextInput: TextInputExport = ({
    autoComplete = 'off',
    errorMessage = '',
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    isDirty = false, // keep isDirty prop, even though it's not used, so integration with final-form is smoooth.
    isDisabled = false,
    isInvalid = false,
    isValid = false,
    name,
    id = '',
    onChange,
    pattern,
    suffix = null,
    prepend = null,
    type = Type.TEXT,
    theme = Theme.LIGHT,
    value,
    dataId,
    elementRef = null,
    isLightBg = false,
    isDockedTop = false,
    onClear,
    clearIcon,
    ...restProps
}: ITextInputProps) => {
    const themeClass = styles[`isTheme${toTitleCase(theme)}`];
    const inputClass = cx(styles.inputWrapper, themeClass, {
        [styles.isDisabled]: isDisabled,
        [styles.isInvalid]: isInvalid,
        [styles.isValid]: isValid,
        [styles.isLightBg]: isLightBg,
        [styles.shortPrepend]: prepend && prepend.length === 1,
        [styles.mediumPrepend]: prepend && prepend.length === 2,
        [styles.longPrepend]: prepend && prepend.length > 2,
        [styles.isDockedTop]: isDockedTop,
    });
    const prependClass = cx(styles.prependWrapper, themeClass, {
        [styles.isInvalid]: isInvalid,
        [styles.isDisabled]: isDisabled,
        [styles.isValid]: isValid,
    });
    const suffixClass = cx(styles.suffixWrapper, themeClass, {
        [styles.isDisabled]: isDisabled,
    });

    return (
        <div className={styles.component} data-id={dataId || name} data-qa="text-input">
            <div className={styles.wrapper}>
                {prepend && (
                    <div className={prependClass} data-qa="prepend">
                        <Text.Body1>{prepend}</Text.Body1>
                    </div>
                )}

                <input
                    autoComplete={autoComplete}
                    className={inputClass}
                    disabled={isDisabled}
                    name={name}
                    onChange={onChange}
                    pattern={pattern}
                    type={type}
                    value={value}
                    data-qa="input"
                    ref={elementRef}
                    id={id || name}
                    {...restProps}
                />

                {value && clearIcon && onClear ? (
                    <div className={cx(styles.clearIcon)}>
                        <ClickableIcon dataId="clear-text-icon" onClick={onClear}>
                            <StatusErrorIcon height={19.09} width={19.09} />
                        </ClickableIcon>
                    </div>
                ) : null}

                {suffix && (
                    <div className={suffixClass} data-qa="suffix">
                        {isValidElement(suffix) ? suffix : <Text.Body1>{suffix}</Text.Body1>}
                    </div>
                )}
            </div>

            {errorMessage && (
                <div className={styles.errorMessage} data-qa="error-message">
                    <Criteria theme={theme} displayState={Criteria.DisplayState.WARNING}>
                        {errorMessage}
                    </Criteria>
                </div>
            )}
        </div>
    );
};

TextInput.Theme = Theme;
TextInput.Type = Type;

export default TextInput;
