import { EN_GB, EN_ZA, EN_AU, EN_CA, EN_NZ } from '@clearscore-group/lib.config.i18n';

export default {
    [EN_GB]: {
        section: 'hero',
        heading: 'Protect your identity with ClearScore. For free, forever.',
        blurb: 'Sign up to ClearScore to get your free ID monitoring report and check that your passwords are not compromised on the dark web - all under 2 minutes.',
        ctaText: 'Get ClearScore Protect',
        ctaTextLong: 'Get ClearScore Protect',
        ctaUrl: 'https://app.clearscore.com/signup',
        footer: 'Shed some light on it',
        clearScore: 'ClearScore',
        protect: 'Protect',
    },
    [EN_ZA]: {
        section: 'hero',
        heading: 'Protect your identity with ClearScore. For free, forever.',
        blurb: 'Sign up to ClearScore to get free identity protection and check that your passwords are not stolen – all under 2 minutes.',
        ctaText: 'Get ClearScore Protect',
        ctaTextLong: 'Get ClearScore Protect',
        ctaUrl: 'https://app.clearscore.com/signup?lng=en-za',
        footer: 'Shed some light on it',
        clearScore: 'ClearScore',
        protect: 'Protect',
    },
    [EN_AU]: {
        section: 'hero',
        heading: 'Protect your identity with ClearScore. For free, forever.',
        blurb: 'Sign up to ClearScore to get your free ID monitoring report and check that your passwords are not compromised on the dark web - all under 2 minutes.',
        ctaText: 'Get ClearScore Protect',
        ctaTextLong: 'Get ClearScore Protect',
        ctaUrl: 'https://app.clearscore.com/signup?lng=en-au',
        footer: 'Shed some light on it',
        clearScore: 'ClearScore',
        protect: 'Protect',
    },
    [EN_CA]: {
        section: 'hero',
        heading: 'Protect your identity with ClearScore. For free, forever.',
        blurb: 'Sign up to ClearScore to get free identity protection and check that your passwords are not stolen – all under 3 minutes.',
        ctaText: 'Get ClearScore Protect',
        ctaTextLong: 'Get ClearScore Protect',
        ctaUrl: 'https://app.clearscore.com/signup?lng=en-ca',
        footer: 'Shed some light on it',
        clearScore: 'ClearScore',
        protect: 'Protect',
    },
    [EN_NZ]: {
        section: 'hero',
        heading: 'Protect your identity with ClearScore. For free, forever.',
        blurb: 'Sign up to ClearScore to get your free ID monitoring report and check that your passwords are not compromised on the dark web - all under 2 minutes.',
        ctaText: 'Get ClearScore Protect',
        ctaTextLong: 'Get ClearScore Protect',
        ctaUrl: 'https://app.clearscore.com/signup?lng=en-nz',
        footer: 'Shed some light on it',
        clearScore: 'ClearScore',
        protect: 'Protect',
    },
};
