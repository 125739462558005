import React from 'react';
import PropTypes from 'prop-types';
import Text from '@clearscore/ui.rainbow.text';
import Tag from '@clearscore/ui.rainbow.tag';
import cx from 'classnames';
import { GatsbyImage } from 'gatsby-plugin-image';
import Container from '@clearscore/ui.rainbow.container';
import useLanguage from '@clearscore-group/lib.hooks.use-language';

import Form, { buttonThemes } from '../create-prospect-form';
import lang from './lib/lang';
import styles from './works.module.css';

const HowProtectWorks = ({ phone }) => {
    const language = useLanguage(lang);
    return (
        <section className={cx(styles.section)} data-qa="how-protect-works">
            <Container size={Container.Size.HUGE}>
                <div className={styles.sectionContent}>
                    <div className={styles.title}>
                        <Text.H1 tag={Text.tags.H2} dataId="how-protect-works-title">
                            {language.header}
                        </Text.H1>
                    </div>
                    {phone && (
                        <div className={styles.phoneContainer}>
                            <GatsbyImage image={phone} alt="Dark Web Market Sign" />
                        </div>
                    )}
                    <div className={styles.textContainer} data-qa="how-protect-works-text-container">
                        <div className={styles.textHeader}>
                            <Text.H3>{language.subHeader}</Text.H3>
                        </div>

                        <div className={styles.textBody}>
                            {language.howItWorksHeader.map((header) => (
                                <div className={styles.textItem} key={header}>
                                    <Text.Body1 tag={Text.tags.DIV}>{header}</Text.Body1>
                                </div>
                            ))}

                            {language.howItWorksItems.map((item) => (
                                <div className={styles.textItem} key={item.key}>
                                    <Text.Body1 tag={Text.tags.DIV}>
                                        {item.preTagCopy}
                                        {item.tag ? <Tag displayState={item.displayState}>{item.tag}</Tag> : null}
                                        {item.postTagCopy}
                                    </Text.Body1>
                                </div>
                            ))}
                        </div>
                        <div className={styles.getProtect}>
                            <Form showEmail={false} language={language} buttonTheme={buttonThemes.LIGHT} />
                        </div>
                    </div>
                </div>
            </Container>
        </section>
    );
};

HowProtectWorks.propTypes = {
    phone: PropTypes.node,
};

HowProtectWorks.defaultProps = {
    phone: null,
};

export default HowProtectWorks;
