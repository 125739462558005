/*
 Check to see if an value is empty, for example null, undefined, '', or an object with no keys
 */
function isEmpty(value) {
    if (value === undefined || value === null || value === '') {
        return true;
    }

    return typeof value === 'object' && Object.keys(value).length === 0;
}

export default isEmpty;
