export const Theme = {
    LIGHT: 'LIGHT',
    DARK: 'DARK',
    CS24: 'CS24',
} as const;

export const Type = {
    EMAIL: 'email',
    NUMBER: 'number',
    PASSWORD: 'password',
    TEXT: 'text',
    DATE: 'date',
    TEL: 'tel',
} as const;
