import { GB, ZA, AU, CA, NZ } from '@clearscore-group/lib.config.i18n';

export default {
    [GB]: {
        hasPlus: true,
    },
    [ZA]: {
        hasPlus: true,
    },
    [AU]: {
        hasPlus: true,
    },
    [NZ]: {
        hasPlus: true,
    },
    [CA]: {
        hasPlus: true,
    },
};
