import React from 'react';
import cx from 'classnames';
import Text from '@clearscore/ui.rainbow.text';
import useLanguage from '@clearscore-group/lib.hooks.use-language';

import Form, { buttonThemes, inputThemes } from '../create-prospect-form';
import lang from './lib/lang';
import styles from './why.module.css';

const WhyClearScore = () => {
    const language = useLanguage(lang);
    return (
        <section className={cx(styles.section, styles.whyClearScore)} data-qa="why-clearscore">
            <div className={styles.sectionContent}>
                <div className={styles.title}>
                    <Text.H1 tag={Text.tags.H2} dataId="why-clearscore-title">
                        {language.heading}
                    </Text.H1>
                </div>

                <div
                    className={cx(styles.textContainer, styles.whyClearScoreTextContainer)}
                    data-qa="why-clearscore-text-container"
                >
                    <div className={styles.textContainer}>
                        <Text.Body1>{language.blurb1}</Text.Body1>
                    </div>
                    <div className={styles.textContainer}>
                        <Text.Body1>{language.blurb2}</Text.Body1>
                    </div>
                </div>
                <div className={styles.emailForm}>
                    <Form
                        showEmail
                        language={language}
                        buttonTheme={buttonThemes.LIGHT}
                        inputTheme={inputThemes.LIGHT}
                    />
                </div>
            </div>
        </section>
    );
};

export default WhyClearScore;
