import useWesbiteTracking from '@clearscore-group/lib.hooks.use-website-tracking';

const PROTECT_LANDING_EVENT = 'fe_protect_landing_page_cta_clicked';
const PROTECT_SIGN_UP_EVENT = 'fe_protect_landing_page_email_submitted';

export default () => {
    const track = useWesbiteTracking();

    return {
        trackEmailSignupAttempt: ({ language, isEmailSubmitSuccessful }) =>
            track({
                name: PROTECT_SIGN_UP_EVENT,
                props: {
                    e: PROTECT_SIGN_UP_EVENT,
                    section_header_copy: language.heading,
                    section_body_copy: language.blurb,
                    module_type: `protect-${language.section}`,
                    email_submit_successful: isEmailSubmitSuccessful.toString(),
                    cta_copy: language.ctaText,
                    cta_url: language.ctaUrl,
                },
            }),
        trackProtectClick: ({ e, language }) => {
            if (e) e.preventDefault();
            track({
                name: PROTECT_LANDING_EVENT,
                props: {
                    e: PROTECT_LANDING_EVENT,
                    module_type: `protect-${language.section}`,
                    cta_copy: language.ctaText,
                    cta_url: language.ctaUrl,
                },
                onComplete: () => {
                    window.location.assign(language.ctaUrl);
                },
            });
        },
    };
};
