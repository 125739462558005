import React from 'react';
import type { ReactNode } from 'react';
import cx from 'classnames';

import styles from './stack.module.css';
import type { breakpointNames } from './constants';
import { spacing } from './constants';

type ValueOf<T> = T[keyof T];
type Spacing = ValueOf<typeof spacing>;

type GetClassNamesArg = Pick<StackProps, (typeof breakpointNames)[number]>;

const getClassNames = (breakpoints: GetClassNamesArg): Record<string, boolean> =>
    Object.entries(breakpoints).reduce<Record<string, boolean>>((prevSize, [breakpoint, classNameModifier]) => {
        // do not add @all when it is for all screen sizes
        const className = breakpoint === 'all' ? classNameModifier : `${classNameModifier}-at-${breakpoint}`;
        return {
            ...prevSize,
            [styles[className]]: !!styles[className],
        };
    }, {});

export interface StackProps {
    children?: ReactNode | undefined;
    all?: Spacing;
    small?: Spacing;
    phablet?: Spacing;
    medium?: Spacing;
    large?: Spacing;
    huge?: Spacing;
    dataId?: string;
    className?: string;
}

const Stack: React.FC<StackProps> & { spacings: typeof spacing } = ({
    children = null,
    dataId,
    className,
    ...breakpoints
}) => {
    const classNames = getClassNames(breakpoints);
    return (
        <div className={cx(classNames, className)} data-id={dataId}>
            {children}
        </div>
    );
};

Stack.spacings = spacing;

export default Stack;
