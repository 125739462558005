import React from 'react';
import PropTypes from 'prop-types';
import Text from '@clearscore/ui.rainbow.text';
import cx from 'classnames';
import useLanguage from '@clearscore-group/lib.hooks.use-language';
import ProtectShieldIcon from '@clearscore/rainbow.icons.shield-monitor';

import Form, { buttonThemes, inputThemes } from '../create-prospect-form';
import lang from './lib/lang';
import styles from './hero.module.css';
import Torch from '../assets/torch.svg';

const Hero = ({ video, reference }) => {
    const language = useLanguage(lang);

    return (
        <section className={cx(styles.section, styles.hero)} data-qa="hero" ref={reference}>
            {video && (
                <video className={styles.video} autoPlay loop muted playsInline>
                    <source src={video} type="video/mp4" />
                </video>
            )}
            <div className={styles.sectionContent}>
                <div className={styles.heroContent}>
                    <div className={styles.logoContainer}>
                        <ProtectShieldIcon width={36} height={36} />
                        <div className={styles.logoText}>
                            <span className={styles.clearScore}>{language.clearScore}</span>
                            {language.protect}
                        </div>
                    </div>

                    <div className={styles.title}>
                        <Text.H1 dataId="hero-title">{language.heading}</Text.H1>
                    </div>
                    <div className={styles.blurb}>
                        <Text.H3 tag={Text.tags.P}>{language.blurb}</Text.H3>
                    </div>
                    <div className={styles.getProtect}>
                        <div className={styles.getProtectButton} data-qa="hero-cta">
                            <Form showEmail={false} language={language} buttonTheme={buttonThemes.DARK} />
                        </div>
                        <div className={styles.getProtectForm} data-qa="hero-cta-email">
                            <Form
                                showEmail
                                language={language}
                                buttonTheme={buttonThemes.DARK}
                                inputTheme={inputThemes.DARK}
                            />
                        </div>
                    </div>
                </div>
                <div className={styles.heroFooter}>
                    <div className={styles.copyright}>
                        <Text.Body3 dataId="copyright">© Clear Score Technology Ltd. All Rights Reserved.</Text.Body3>
                    </div>
                    <div className={styles.shed}>
                        <div className={styles.torchContainer}>
                            <div className={styles.tagline}>
                                <Text.Button1 dataId="hero-tagline">Shed some light on it</Text.Button1>
                            </div>

                            <Torch className={styles.torch} />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

Hero.propTypes = {
    video: PropTypes.node,
    reference: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.any })]),
};

Hero.defaultProps = {
    video: null,
    reference: null,
};

export default Hero;
