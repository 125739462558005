import { EN_GB, EN_ZA, EN_AU, EN_CA, EN_NZ } from '@clearscore-group/lib.config.i18n';

export default {
    [EN_GB]: {
        section: 'your-data',
        heading: 'Your data in your hands',
        blurb1: 'ClearScore began by giving everyone access to their credit score and report for free, which is a great way to spot early signs of fraud. But with fraud levels reaching record highs, we want to help everyone better protect their identity.',
        blurb2: 'So we created ClearScore Protect: protecting your identity, for free, forever.',
        ctaText: 'Get Protect',
        ctaTextLong: 'Get ClearScore Protect',
        ctaUrl: 'https://app.clearscore.com/signup',
    },
    [EN_ZA]: {
        section: 'your-data',
        heading: 'Your data, in your hands',
        blurb1: 'ClearScore began by giving everyone access to their credit score and report for free, which is a great way to spot early signs of fraud. But with fraud levels reaching record highs, we want to help everyone better protect their identity.',
        blurb2: 'So we created ClearScore Protect: protecting your identity, for free, forever.',
        ctaText: 'Get Protect',
        ctaTextLong: 'Get ClearScore Protect',
        ctaUrl: 'https://app.clearscore.com/signup?lng=en-za',
    },
    [EN_AU]: {
        section: 'your-data',
        heading: 'Your data is in your hands',
        blurb1: 'We created ClearScore protect to arm you with tools to check that your financial data is secure. We have teamed up with cyber security experts who monitor the dark web for criminal activity and search for stolen data such as usernames and passwords. They are collecting more and more information every day - currently their secure database has over 41 billion data points in it. If we find a match to your account, we will alert you so you can take action. Helping you fight back against this crisis.',
        blurb2: 'ClearScore Protect: protecting your identity, for free, forever',
        ctaText: 'Get Protect',
        ctaTextLong: 'Get ClearScore Protect',
        ctaUrl: 'https://app.clearscore.com/signup?lng=en-au',
    },
    [EN_CA]: {
        section: 'your-data',
        heading: 'Your data, in your hands',
        blurb1: 'ClearScore began by giving everyone access to their credit score and report for free, which is a great way to spot early signs of fraud. But with fraud levels reaching record highs, we want to help everyone better protect their identity.',
        blurb2: 'So we created ClearScore Protect: protecting your identity, for free, forever.',
        ctaText: 'Get Protect',
        ctaTextLong: 'Get ClearScore Protect',
        ctaUrl: 'https://app.clearscore.com/signup?lng=en-ca',
    },
    [EN_NZ]: {
        section: 'your-data',
        heading: 'Your data is in your hands',
        blurb1: 'We created ClearScore protect to arm you with tools to check that your financial data is secure. We have teamed up with cyber security experts who monitor the dark web for criminal activity and search for stolen data such as usernames and passwords. They are collecting more and more information every day - currently their secure database has over 41 billion data points in it. If we find a match to your account, we will alert you so you can take action. Helping you fight back against this crisis.',
        blurb2: 'ClearScore Protect: protecting your identity, for free, forever',
        ctaText: 'Get Protect',
        ctaTextLong: 'Get ClearScore Protect',
        ctaUrl: 'https://app.clearscore.com/signup?lng=en-nz',
    },
};
