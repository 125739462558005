import React from 'react';
import Template from '@clearscore/shared.website-template';
import PropTypes from 'prop-types';
import useMarketConfig from '@clearscore-group/lib.hooks.use-market-config-deprecated';

import styles from './protect-landing.module.css';
import Hero from './components/hero';
import Details from './components/details';
import HowProtectWorks from './components/works';
import Plus from './components/plus';
import FAQ from './components/faq';
import config from './lib/market-config';
import WhyClearScore from './components/why';
import useUtmSource from './hooks/use-utm-source';

const ProtectLandingPage = ({ appStoreConfig, cookiePolicyConfig, video, sign, phone, faq, shield }) => {
    const { hasPlus } = useMarketConfig(config);
    const utmSource = useUtmSource();

    const navigationConfig = {
        isProtect: true,
        signUpQueryString: `?utm_source=${utmSource || 'protect_landing'}&utm_campaign=protect`,
    };

    return (
        <Template
            pullUpContent
            appStoreConfig={appStoreConfig}
            cookiePolicyConfig={cookiePolicyConfig}
            navigationConfig={navigationConfig}
        >
            <div className={styles.protect}>
                <div className={styles.sectionContainer}>
                    <Hero video={video} utmSource={utmSource} />
                    <Details sign={sign} utmSource={utmSource} />
                    <HowProtectWorks phone={phone} utmSource={utmSource} />
                    {hasPlus ? <Plus shield={shield} /> : null}
                    <FAQ faq={faq} />
                    <WhyClearScore utmSource={utmSource} />
                </div>
            </div>
        </Template>
    );
};

ProtectLandingPage.propTypes = {
    appStoreConfig: PropTypes.shape({
        googlePlayUrl: PropTypes.string,
        appStoreUrl: PropTypes.string,
        appStoreLink: PropTypes.string,
        playStoreLink: PropTypes.string,
        appleStoreToggle: PropTypes.bool,
        googleStoreToggle: PropTypes.bool,
    }).isRequired,
    cookiePolicyConfig: PropTypes.shape({
        moreInfoLinkUrl: PropTypes.string.isRequired,
        enabled: PropTypes.bool.isRequired,
    }).isRequired,
    video: PropTypes.node,
    sign: PropTypes.node,
    phone: PropTypes.node,
    faq: PropTypes.node,
    shield: PropTypes.node,
};

ProtectLandingPage.defaultProps = {
    video: null,
    sign: null,
    phone: null,
    faq: null,
    shield: null,
};

export default ProtectLandingPage;
