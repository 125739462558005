import createValidator from '@clearscore-group/lib.validation.create';
import { isEmailValidation } from '@clearscore-group/lib.validation.is-email';
import { isRequiredValidation } from '@clearscore-group/lib.validation.is-required';

import lang from './lang';

const createValidation = createValidator({
    email: [isRequiredValidation(lang.enterValidEmail), isEmailValidation(lang.enterValidEmail)],
});

export default createValidation;
