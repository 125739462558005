import type { ReactElement, SVGProps } from 'react';
import React from 'react';
import cx from 'classnames';
import TickIcon from '@clearscore/rainbow.icons.success-inline';
import WarningIcon from '@clearscore/rainbow.icons.warning-inline';
import ErrorIcon from '@clearscore/rainbow.icons.error-inline';
import Text from '@clearscore/ui.rainbow.text';
import toTitleCase from '@clearscore-group/lib.normalize.title-case';

import styles from './criteria.module.css';

const Theme = {
    LIGHT: 'LIGHT',
    DARK: 'DARK',
    CS24: 'CS24',
} as const;

const DisplayState = {
    SUCCESS: 'SUCCESS',
    WARNING: 'WARNING',
    PENDING: 'PENDING',
    ERROR: 'ERROR',
} as const;

type IconType = (props: SVGProps<SVGElement> & { title?: string }) => ReactElement;

const getIcon = (displayState: keyof typeof DisplayState): IconType => {
    if (displayState === DisplayState.WARNING) {
        return WarningIcon;
    }
    if (displayState === DisplayState.ERROR || displayState === DisplayState.PENDING) {
        return ErrorIcon;
    }
    return TickIcon;
};

interface CriteriaProps {
    displayState: keyof typeof DisplayState;
    children?: string;
    theme?: (typeof Theme)[keyof typeof Theme];
    dataId?: string;
}

export interface CriteriaExport {
    (props: CriteriaProps): ReactElement;
    DisplayState: typeof DisplayState;
    Theme: typeof Theme;
}

const Criteria: CriteriaExport = ({ children = '', theme = Theme.LIGHT, displayState, dataId = '' }) => {
    const Icon = getIcon(displayState);
    const themeClass = styles[`isTheme${toTitleCase(theme)}`];
    const typeClass = styles[`isType${toTitleCase(displayState)}`];
    const iconTitle = `${children} icon`;

    return (
        <div
            data-id={dataId}
            data-qa="criteria"
            className={cx(styles.component, themeClass, {
                [styles.isVisible]: !!children,
            })}
        >
            <span className={cx(styles.icon, typeClass)}>
                <Icon title={iconTitle} width={14} height={14} />
            </span>
            <Text.Caption>{children}</Text.Caption>
        </div>
    );
};

Criteria.DisplayState = DisplayState;
Criteria.Theme = Theme;

export default Criteria;
