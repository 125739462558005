import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Text from '@clearscore/ui.rainbow.text';
import ContentCarousel from '@clearscore/ui.rainbow.content-carousel';
import useLanguage from '@clearscore-group/lib.hooks.use-language';
import { GatsbyImage } from 'gatsby-plugin-image';
import interpolateWithComponents from '@clearscore-group/lib.helpers.interpolate-with-components';

import lang from './lib/lang';
import styles from './faq.module.css';
import Torch from '../assets/torch.svg';

const FAQ = ({ faq }) => {
    const language = useLanguage(lang);
    return (
        <section className={cx(styles.section)} data-qa="faq-section">
            {faq && (
                <div className={styles.backgroundImage}>
                    <GatsbyImage image={faq} alt="Woman looking down" />
                    <div className={styles.tint} />
                </div>
            )}
            <div className={styles.faq}>
                <div className={styles.headerSection} data-qa="faq-header-section">
                    <div className={cx(styles.title, styles.header)}>
                        <Text.H1 tag={Text.tags.H2} dataId="faq-title">
                            {language.header}
                        </Text.H1>
                    </div>
                </div>
                <div className={styles.torchContainer}>
                    <Torch className={styles.torch} />
                </div>
                <div className={cx(styles.sectionContent)}>
                    <div className={styles.carouselContainer}>
                        <ContentCarousel showDots inlineAt={ContentCarousel.Size.LARGE}>
                            {language.faqs.map((item) => (
                                <div key={item.key} className={styles.carouselTextContainer}>
                                    <div className={styles.textHeader}>
                                        <Text.H3>{item.header}</Text.H3>
                                    </div>
                                    <div className={styles.textBody}>
                                        {item.ctaUrl ? (
                                            <Text.Body1>
                                                {interpolateWithComponents(item.copy, [
                                                    {
                                                        Component: Text.Link,
                                                        props: { href: item.ctaUrl, isNewTab: true },
                                                    },
                                                ])}
                                            </Text.Body1>
                                        ) : (
                                            <Text.Body1>{item.copy}</Text.Body1>
                                        )}
                                    </div>
                                </div>
                            ))}
                        </ContentCarousel>
                    </div>
                </div>
            </div>
        </section>
    );
};

FAQ.propTypes = {
    faq: PropTypes.node,
};

FAQ.defaultProps = {
    faq: null,
};

export default FAQ;
