import Tag from '@clearscore/ui.rainbow.tag';
import { EN_GB, EN_ZA, EN_AU, EN_CA, EN_NZ } from '@clearscore-group/lib.config.i18n';

export default {
    [EN_GB]: {
        section: 'take-control',
        header: 'Take control with ClearScore Protect',
        subHeader: 'Sign up in just 2 minutes to get your free credit report and quarterly dark web scan',
        howItWorksHeader: [
            "We've partnered with cyber security experts who search the dark web for stolen data and save it in a secure database.",
            'Every month we’ll scan this database for stolen passwords associated with your email address.',
        ],
        howItWorksItems: [
            {
                preTagCopy: 'If we find anything, we’ll let you know so you can change your ',
                tag: 'Passwords',
                postTagCopy: ' and take control.',
                displayState: Tag.displayState.NEGATIVE,
                key: 'how-it-works-1',
            },
        ],
        ctaText: 'Get ClearScore Protect',
        ctaTextLong: 'Get ClearScore Protect',
        ctaUrl: 'https://app.clearscore.com/signup',
    },
    [EN_ZA]: {
        section: 'take-control',
        header: 'Take control with ClearScore Protect',
        subHeader: 'Sign up in just 2 minutes to get your free security score and start protecting your identity.',
        howItWorksHeader: [
            "We've partnered with cyber security experts who search the dark web for stolen data and save it in a secure database.",
            'Every month we’ll scan this database for stolen passwords associated with your email address.',
        ],
        howItWorksItems: [
            {
                preTagCopy: 'If we find anything, we’ll let you know so you can change your ',
                tag: 'Passwords',
                postTagCopy: ' and take control.',
                displayState: Tag.displayState.NEGATIVE,
                key: 'how-it-works-1',
            },
        ],
        ctaText: 'Get ClearScore Protect',
        ctaTextLong: 'Get ClearScore Protect',
        ctaUrl: 'https://app.clearscore.com/signup?lng=en-za',
    },
    [EN_AU]: {
        section: 'take-control',
        header: 'How ClearScore Protect works',
        subHeader: 'Sign up in just 2 minutes to get your free ID monitoring and quarterly dark web scan',
        howItWorksHeader: [
            "We've partnered with cyber security experts who search the dark web for stolen data and save it in a secure database.",
            'Every month we’ll scan this database for stolen passwords associated with your email address.',
        ],
        howItWorksItems: [
            {
                preTagCopy: 'If we find anything, we’ll let you know so you can change your ',
                tag: 'Passwords',
                postTagCopy: ' and take control.',
                displayState: Tag.displayState.NEGATIVE,
                key: 'how-it-works-2',
            },
        ],
        ctaText: 'Get ClearScore Protect',
        ctaTextLong: 'Get ClearScore Protect',
        ctaUrl: 'https://app.clearscore.com/signup?lng=en-au',
    },
    [EN_CA]: {
        section: 'take-control',
        header: 'How ClearScore Protect works',
        subHeader: 'Sign up in just 3 minutes to get your free ID monitoring and quarterly dark web scan',
        howItWorksHeader: [
            "We've partnered with cyber security experts who search the dark web for stolen data and save it in a secure database.",
        ],
        howItWorksItems: [
            {
                preTagCopy: 'Every  ',
                postTagCopy: 'month we’ll scan this database for stolen passwords associated with your email address.',
                displayState: 'positive',
                key: 'how-it-works-1',
            },
            {
                preTagCopy: 'If we find anything, we’ll let you know so you can change your ',
                tag: 'Passwords',
                postTagCopy: ' and take control.',
                displayState: Tag.displayState.NEGATIVE,
                key: 'how-it-works-2',
            },
        ],
        ctaText: 'Get ClearScore Protect',
        ctaTextLong: 'Get ClearScore Protect',
        ctaUrl: 'https://app.clearscore.com/signup?lng=en-ca',
    },
    [EN_NZ]: {
        section: 'take-control',
        header: 'How ClearScore Protect works',
        subHeader: 'Sign up in just 2 minutes to get your free ID monitoring and quarterly dark web scan',
        howItWorksHeader: [
            "We've partnered with cyber security experts who search the dark web for stolen data and save it in a secure database.",
        ],
        howItWorksItems: [
            {
                preTagCopy: 'Every  ',
                tag: 'month',
                postTagCopy: ' we’ll scan this database for stolen passwords associated with your email address.',
                displayState: 'positive',
                key: 'how-it-works-1',
            },
            {
                preTagCopy: 'If we find anything, we’ll let you know so you can change your ',
                tag: 'Passwords',
                postTagCopy: ' and take control.',
                displayState: Tag.displayState.NEGATIVE,
                key: 'how-it-works-2',
            },
        ],
        ctaText: 'Get ClearScore Protect',
        ctaTextLong: 'Get ClearScore Protect',
        ctaUrl: 'https://app.clearscore.com/signup?lng=en-nz',
    },
};
